@import "../../styles/vars";

.card-wrap-container .wrapping-row-item {
  .card-details {
    padding: 0em 2em 0 1em;

    .member-photo-container {
      img {
        margin: 0 0 0 1em;
        width: 7em;
        height: 7em;
      }
      .red-circle {
        margin: 1em 0 0.5em 0;
        background-color: rgb(255, 87, 87);
        height: 1.5em;
        width: 1.5em;
        border-radius: 50%;
        box-shadow: $box-shadow;
      }
      .green-circle {
        margin: 1em 0 0.5em 0;
        background-color: rgb(18, 165, 18);
        height: 1.5em;
        width: 1.5em;
        border-radius: 50%;
        box-shadow: $box-shadow;
      }
    }

    .card-info {
      margin: 1.25em 0 0 0;
    }
  }
}
