@import "./_vars";
.master-container {
  .master-content {
    .report-summary-card {
      .summary-header {
        padding: 1em 0 2em 1em;
        p {
          color: white;
        }
      }
      .header-request {
        background-color: $request;
      }
      .text-request {
        color: $request;
      }
      .header-voice-call {
        background-color: $voice-call;
      }
      .text-voice-call {
        color: $voice-call;
      }
      .header-video-call {
        background-color: $video-call;
      }
      .text-video-call {
        color: $video-call;
      }
      .header-messaging {
        background-color: $messaging;
      }
      .text-messaging {
        color: $messaging;
      }
      .header-vcard {
        background-color: $vcard;
      }
      .text-vcard {
        color: $vcard;
      }
      .header-broadcast {
        background-color: $broadcast;
      }
      .text-broadcast {
        color: $broadcast;
      }
      .report-summary-card-content {
        padding: 0.25em 0 3em 0.25em;
      }
    }
  }
}
