@import "../../styles/vars";
.master-container {
  .master-content {
    .report-table-container {
      display: flex;
      flex-direction: column;
      tbody.MuiTableBody-root > :nth-of-type(2n) {
        background-color: $light-gray;
      }
      thead.material-header-row {
        background-color: #707070;
        td {
          cursor: pointer;
          color: white;
          font-weight: bold;
          font-size: 1em;
          p {
            padding: 1em;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
          }
          .selected {
            color: black;
          }
        }
        td:hover {
          color: black;
        }
        .header-disabled {
          color: white;
        }
        .header-disabled:hover {
          color: white;
          cursor: auto;
        }
      }

      .spinner {
        align-self: center;
      }
    }
    .report-summary-filters {
      .wrap {
        width: 100%;
      }
      .filter-input {
        max-width: 9em;
        width: 100%;
        margin: 0 1em 0 0;
      }
      .report-table-button {
        button {
          font-size: 0.75em;
          min-width: 10em;
        }
      }
    }
    .table-small {
      max-width: 43em;
    }
    .table-wide {
      max-width: 76em;
    }
  }
}
