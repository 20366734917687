.master-container {
  .master-content {
    .summary-filter-form {
      margin-right: 3em;
      .summary-date-input {
        width: 10em;
        margin: 0 2em 0 0;
      }
      .summary-form-button {
        height: 0.75em;
        button {
          font-size: 0.75em;
        }
      }
      .clear-filters {
        margin: 0 2em 0 0;
      }
    }
  }
}
